.vsenha{
position: relative;
 
}
.vsenha button{
  position: absolute;
 right: 0;
  background: none;
  border: 0;
  padding: 12px;
}
button.load{
  position: relative;
  background: #dadee0;
  color: #dadee0;
cursor: not-allowed;
  border: solid 1px rgb(0,0,0,0.1);
}
button.load:hover{
  position: relative;
  cursor: not-allowed;
  background: #dadee0;
  color: #dadee0;;
  border: solid 1px rgb(0,0,0,0.1);
}
button.load::after {
  content: "";
  position: absolute;
  background-image: url("https://cdn.rifei.co/storage/images/icons/dark-spinner.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 22px;
  width: 22px;
  height: 22px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

button.load:disabled{
  position: relative;
  cursor: not-allowed;
  background: #dadee0;
  color: #dadee0;;
  border: solid 1px rgb(0,0,0,0.1);
}
