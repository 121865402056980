.my-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.page-lgn{

}
.rp-sen{
  display: flex;
  justify-content: end;
}
.rp-sen a{
  text-decoration: none;
}
.apst{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
}
.area-spass{
  position: relative;
 display: flex;
  justify-content: center;
  align-items: center;
}

.area-spass button{
  position: absolute;
  right: 10px;
  background: transparent;
  border: 0;

}
.page-lgn .area{
 display: flex;
  justify-content: center; /* Alinha o conteúdo horizontalmente no centro */
  align-items: center;    /* Alinha o conteúdo verticalmente no centro */
  height: 100vh;          /* Define a altura da div como 100% da altura da janela */

}
.a-lg .row{
  position: absolute;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
}
@media (max-width: 767px) {
  .img-fluid{
    display: none;
  }
  .page-lgn .container-fluid div .col-md-9{
display: none;
  }
}

.container-2 {
  display: flex;
  width: 50%;
  height: auto;
  border: 1px solid rgb(0,0,0,0.2);
  border-radius: 10px;
  overflow: hidden;
}
.left, .right {
  flex: 1;
  padding: 20px;
}
.left {
  background-color: #ffffff;
}
.right {
  background-color: #301150;
}
.ipts-lg input,button{
  height: 45px;
}
.ipts-lg input:focus{
 box-shadow: none;
}